<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Servidores / Gerenciamento OLT</h1>
    <div v-if="(olt)" class="form-group card-principal">
      <div class="row justify-content-between">
        <div class="col mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
        <div class="col-auto mb-3">
          <button @click="handleRunInitialLoad()" class="btn btn-warning" v-if="(!olt.synced)">
            <font-awesome-icon :icon="['fas', 'cloud-download-alt']" fixed-width/>
            Executar carga inicial
          </button>
          <!-- <Tooltip position="left" :tooltipText="'Sincronizar OLT com o SGM Cloud'">
            <button v-on:click="handleSincronizaOltFullLoad()"
                class="btn btn-outline-success"
                id="btn-sync-olt"
                type="button">
              <font-awesome-icon :icon="['fas', 'sync']" fixed-width class="me-1"/>
            </button>
          </Tooltip> -->
        </div>
      </div>
      <div class="alert alert-primary" role="alert">
        <p class="mb-0"><strong>OLT:</strong> {{olt.description}}</p>
        <p class="mb-0"><strong>IP:</strong> {{olt.ip}}</p>
        <p class="mb-0 text-danger" v-if="(!olt.synced)">
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width/>
          <strong class="ms-1">Carga inicial pendente</strong>
        </p>
      </div>
      <nav>
        <div class="nav nav-tabs" id="nav-tab-olt-manage" role="tablist">
          <a class="nav-link active" id="nav-vlan-tab" data-bs-toggle="tab" href="#nav-vlan" role="tab" aria-controls="nav-vlan" aria-selected="true">
            <font-awesome-icon :icon="['fas', 'people-arrows']" fixed-width class="me-1"/>
            VLAN's
          </a>
          <a class="nav-link" id="nav-bandwidth-tab" data-bs-toggle="tab" href="#nav-bandwidth" role="tab" aria-controls="nav-bandwidth" aria-selected="true">
            <font-awesome-icon :icon="['fas', 'tachometer-alt']" fixed-width class="me-1"/>
            Perfil de banda
          </a>
          <a class="nav-link" id="nav-tipos-onu-tab" data-bs-toggle="tab" href="#nav-tipos-onu" role="tab" aria-controls="nav-tipos-onu" aria-selected="true">
            <font-awesome-icon :icon="['fas', 'network-wired']" fixed-width class="me-1"/>
            Tipos de ONU
          </a>
          <a class="nav-link" id="nav-placas-tab" data-bs-toggle="tab" href="#nav-placas" role="tab" aria-controls="nav-placas" aria-selected="true">
            <font-awesome-icon :icon="['fas', 'ethernet']" fixed-width class="me-1"/>
            Placas
          </a>
        </div>
      </nav>
      <div class="tab-content" id="nav-tab-olt-manageContent">
        <!-- vlan -->
        <div class="tab-pane fade show active" id="nav-vlan" role="tabpanel" aria-labelledby="nav-vlan-tab">
          <ServidoresOLTVlan/>
        </div>
        <!-- vlan -->

        <!-- bandwidth -->
        <div class="tab-pane fade" id="nav-bandwidth" role="tabpanel" aria-labelledby="nav-bandwidth-tab">
          <ServidoresOLTPerfilBanda/>
        </div>
        <!-- bandwidth -->

        <!-- tipos-onu -->
        <div class="tab-pane fade" id="nav-tipos-onu" role="tabpanel" aria-labelledby="nav-tipos-onu-tab">
          <ServidoresOLTTiposOnu/>
        </div>
        <!-- tipos-onu -->

         <!-- placas -->
        <div class="tab-pane fade" id="nav-placas" role="tabpanel" aria-labelledby="nav-placas-tab">
          <ServidoresOLTPlacas/>
        </div>
        <!-- placas -->
      </div>
    </div>
  </div>
</template>

<script>
// import ServidoresOLTVlan from '@/views/ServidoresOLTVlan.vue'
// import ServidoresOLTPerfilBanda from '@/views/ServidoresOLTPerfilBanda.vue'
// import ServidoresOLTTiposOnu from '@/views/ServidoresOLTTiposOnu.vue'
// import ServidoresOLTPlacas from '@/views/ServidoresOLTPlacas.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
// import Tooltip from "@/components/Tooltip.vue";

export default {
  name: 'ServidoresOLTManage',
  components: {
    // ServidoresOLTVlan,
    // ServidoresOLTPerfilBanda,
    // ServidoresOLTTiposOnu,
    // ServidoresOLTPlacas,
    // Tooltip,
  },
  data() {
    return {
      oltID: undefined,
      olt: undefined,
    }
  },
  methods: {
    carregaOlt() {
      const apiUrl = `/isp/olts/${this.oltID}`;

      loading(true);

      api.get(apiUrl).then(res => {
        loading(false);
        this.olt = res.data;
      }).catch(error => {
        loading(false);

        dialogo.erroHttp(error).then(() => {
          this.$router.push({ path: `/configuracoes/servidores#nav-olt` });
        });
      });
    },

    handleRunInitialLoad() {
      dialogo.confirmacao(`Confirma solicitar sincronização da OLT com o SGM? Este procedimento pode demorar alguns minutos.`).then(() => {
        const apiUrl = `/isp/olts/${this.oltID}/full-load`;

        loading(true);
        api.get(apiUrl, {}).then(() => {
          loading(false);
          dialogo.alerta('A carga inicial foi executada com sucesso.');
          this.carregaOlt();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },
  },
  created() {
    const oltID = +this.$route.params.oltID;

    if (!oltID) {
      this.$router.push({ path: `/configuracoes/servidores#nav-olt` });
      return;
    }

    this.oltID = oltID;

    this.carregaOlt();
  },
  destroyed() {
  },
}
</script>
